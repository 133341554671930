import SLink from '../../../components/ui/s-link'
import STitle from '../../../components/ui/s-title'

export default {
  name: 'WelcomeLogin',

  components: {
    SLink,
    STitle,
  },
}
